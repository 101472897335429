@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

nav a {
  color: #000;
  display: inline-block;
  margin: 0 10px;
}
nav a:hover {
  text-decoration: none;
}
